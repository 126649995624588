/**
 * HeaderRight component that renders the right side of the header.
 * It handles the menu toggle, search bar, and button for creating a market or connecting a wallet.
 *
 */
import { Drawer, Grid } from "antd";
import React, { useContext, useEffect, useState } from "react";
import HeaderMenu from "./Menu/HeaderMenu";
import useWallet from "../../Hooks/useWallet";
import LoggedInMenu from "./Menu/LoggedInMenu";
import { env, msgs } from "../../utils/appConstants";
import { ButtonCustom } from "../ButtonCustom/ButtonCustom";
import { Wallet, WalletIcon } from "../../assets/StoreAsset/StoreAsset";
import {
  getWalletBalance,
  isLoggedIn,
} from "../../utils/helpers/walletHelpers";
import DropdownCustom from "../DropdownCustom/Dropdown";
import {
  formatNumber,
  formattedBalance,
  getDropDownMenu,
} from "../../utils/helpers/commonHelper";
import WalletMenu from "./Menu/WalletMenu";
import { MyLoggedInContext } from "../../context/useLoggedIn";
import { Context } from "../../Pages/ContextProvider/ContextProvider";
import UseGetApi from "../../api/makeRequest";
import { apiUrls } from "../../api/apiUrls";

const HeaderRight = () => {
  const { useBreakpoint } = Grid;
  const { sm, xl } = useBreakpoint();
  const { isDisabled, addNetwork } = useWallet();
  const { fetchBalance, setProfile } = useContext(Context);
  const { loggedInValue } = useContext(MyLoggedInContext);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [metaBalance, setBalance] = useState({
    userBalance: 0,
    betsPlaced: 0,
  });

  const toggleMenu = () => setDrawerOpen(!drawerOpen);

  function WalletFunction() {
    return (
      <DropdownCustom
        menu={getDropDownMenu(WalletMenu({ metaBalance }))}
        icon={<WalletIcon />}
        className="walletHeader"
        overlayClassName="wallet_overlay"
      />
    );
  }

  const userBalance = async () => {
    try {
      const amountInvested = await UseGetApi(apiUrls?.amountInvested());
      const volumeTraded = await UseGetApi(apiUrls?.volumeTraded());
      const getBalance = await formattedBalance(await getWalletBalance());
      const amountInvestedFormatted =
        Number(
          formatNumber(amountInvested?.data?.data?.amountInvested)
        ).toFixed(env?.precision) || 0;
      const volumeFormatted =
        Number(formatNumber(volumeTraded?.data?.data?.volumeTraded)).toFixed(
          env.precision
        ) || 0;

      setProfile((prev) => ({
        ...prev,
        volumeTraded: volumeFormatted,
      }));
      setBalance({
        userBalance: getBalance,
        betsPlaced: amountInvestedFormatted,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loggedInValue) userBalance();
  }, [fetchBalance, loggedInValue]);

  return (
    <>
      <div className="headerHome_right">
        {isLoggedIn() && WalletFunction()}
        {isLoggedIn() && xl ? (
          <LoggedInMenu />
        ) : (
          <ButtonCustom
            label={msgs.connect}
            leftIcon={<Wallet />}
            onClick={() => addNetwork()}
            disabled={isDisabled}
          />
        )}
        {!xl ? (
          <>
            <div className="menuToggle" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Drawer
              placement="right"
              onClose={toggleMenu}
              open={drawerOpen}
              width={sm ? "378px" : "300px"}
              className="drawerMenu"
            >
              <div className="dawerProfile">
                {isLoggedIn() ? (
                  <>
                    <LoggedInMenu toggleMenu={toggleMenu} />
                  </>
                ) : (
                  <span onClick={() => toggleMenu()}>
                    <ButtonCustom
                      label={msgs.connect}
                      leftIcon={<Wallet />}
                      onClick={() => addNetwork()}
                      disabled={isDisabled}
                    />
                  </span>
                )}
              </div>
              <HeaderMenu toggleMenu={toggleMenu} />
            </Drawer>
          </>
        ) : null}
      </div>
    </>
  );
};

export default HeaderRight;
