import React from "react";
import Blockies from "react-blockies";
import ProfileImg from "../../assets/BlueLogo.svg";

const RandomImageGenerator = ({ className, data, alt }) => {
  return (
    <>
      {data != ProfileImg && data ? (
        <img src={data} alt={alt} className={className} />
      ) : (
        <Blockies
          seed={localStorage.getItem("isLogged")}
          size={10}
          scale={3}
          className={`prifileLogo ${className}`}
        />
      )}
    </>
  );
};

export default RandomImageGenerator;
