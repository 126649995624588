// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactUs {
  max-width: 548px;
  width: 100%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #111b24;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 36px 0px rgba(220, 255, 250, 0.08);
}
.ContactUs h2 {
  color: var(--white);
  font-size: var(--headingFive);
  font-weight: 600;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ContactUs/ContactUs.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sDAAA;AACF;AAAE;EACE,mBAAA;EACA,6BAAA;EACA,gBAAA;EACA,mBAAA;AAEJ","sourcesContent":[".ContactUs {\n  max-width: 548px;\n  width: 100%;\n  margin: 100px auto;\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  background: #111b24;\n  padding: 30px;\n  border-radius: 12px;\n  box-shadow: 0px 4px 36px 0px rgba(220, 255, 250, 0.08);\n  h2 {\n    color: var(--white);\n    font-size: var(--headingFive);\n    font-weight: 600;\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
