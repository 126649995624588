// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activityTable {
  margin-top: 30px;
  margin-bottom: 70px;
}
.activityTable.show-cursor tbody tr {
  cursor: pointer;
}
.activityTable .fix_width {
  width: 100%;
}
.activityTable .marketTd {
  display: flex;
  align-items: center;
  gap: 10px;
}
.activityTable .marketTd img,
.activityTable .marketTd canvas {
  width: 21px !important;
  height: 21px !important;
}

.txnHash {
  color: var(--darkPurple);
}`, "",{"version":3,"sources":["webpack://./src/Pages/Activity/Activity.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mBAAA;AACF;AAEM;EACE,eAAA;AAAR;AAIE;EACE,WAAA;AAFJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AAFJ;AAGI;;EAEE,sBAAA;EACA,uBAAA;AADN;;AAMA;EACE,wBAAA;AAHF","sourcesContent":[".activityTable {\n  margin-top: 30px;\n  margin-bottom: 70px;\n  &.show-cursor {\n    tbody {\n      tr {\n        cursor: pointer;\n      }\n    }\n  }\n  .fix_width {\n    width: 100%;\n  }\n  .marketTd {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    img,\n    canvas {\n      width: 21px !important;\n      height: 21px !important;\n    }\n  }\n}\n\n.txnHash {\n  color: var(--darkPurple);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
