// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}
@media (max-width: 1260px) {
  .dashLayout {
    padding-top: 70px;
  }
}
.dashLayout_container {
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/Common/Layouts/DashboardLayout/DashboardLayout.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;AAAE;EALF;IAMI,iBAAA;EAGF;AACF;AAFE;EACE,SAAA;AAIJ","sourcesContent":[".dashLayout {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  padding-top: 100px;\n  @media (max-width: 1260px) {\n    padding-top: 70px;\n  }\n  &_container {\n    flex: 1;\n    // display: flex;\n    // gap: 16px;\n\n    // margin: 0 16px;\n    // @media (max-width: 991px) {\n    //   margin: 0 8px;\n    //   gap: 8px;\n    // }\n    // &_body {\n    //   background-color: var(--white);\n    //   border: 1px solid var(--grayE4Border);\n    //   border-radius: 8px;\n    //   padding: 35px 32px;\n    //   flex: 1;\n    //   overflow-x: hidden;\n    //   height: calc(100vh - 80px);\n    //   overflow-y: auto;\n    //   -webkit-box-shadow: 0px 0px 22px -8px rgba(87, 0, 255, 0.13);\n    //   -moz-box-shadow: 0px 0px 22px -8px rgba(87, 0, 255, 0.13);\n    //   box-shadow: 0px 0px 22px -8px rgba(87, 0, 255, 0.13);\n    //   @media (max-width: 991px) {\n    //     padding: 25px 20px;\n    //     min-height: 100%;\n    //   }\n    // }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
