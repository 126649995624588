// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-upload-list-item-container {
  flex: 1 1;
  height: 150px !important;
}
.ant-upload-list-item-container img {
  width: 100% !important;
  height: 100% !important;
}
.ant-upload-list-item-container .ant-tooltip {
  display: none !important;
}

.upload-box img {
  height: 13em;
  object-fit: cover !important;
}

.ant-upload-list {
  justify-content: space-between;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Common/UploadFileCommon/UploadFileCommon.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,wBAAA;AACF;AAAE;EACE,sBAAA;EACA,uBAAA;AAEJ;AAAE;EACE,wBAAA;AAEJ;;AACA;EACE,YAAA;EACA,4BAAA;AAEF;;AAAA;EACE,8BAAA;EACA,SAAA;AAGF","sourcesContent":[".ant-upload-list-item-container {\n  flex: 1;\n  height: 150px !important;\n  img {\n    width: 100% !important;\n    height: 100% !important;\n  }\n  .ant-tooltip {\n    display: none !important;\n  }\n}\n.upload-box img {\n  height: 13em;\n  object-fit: cover !important;\n}\n.ant-upload-list {\n  justify-content: space-between;\n  gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
