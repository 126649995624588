// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorBoundary {
  text-align: center;
  padding: 15px;
  background-color: var(--Mainblack);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.errorBoundary img {
  max-width: 121px;
  width: 100%;
  height: 50px;
}
.errorBoundary_content {
  max-width: 604px;
  width: 100%;
  padding: 80px 40px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3607843137);
  box-shadow: 0px 0px 22px -8px rgba(255, 255, 255, 0.13);
}
.errorBoundary_content .back-to-home {
  display: flex;
  align-items: center;
  margin: 30px 164px 0px;
}
@media (max-width: 767px) {
  .errorBoundary_content {
    padding: 40px 15px;
  }
}
.errorBoundary_content h4 {
  font-size: var(--headingOne);
  color: var(--white);
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 20px;
}
.errorBoundary_content h6 {
  font-size: var(--largeParaGraph);
  color: var(--white);
  font-weight: 400;
  margin-bottom: 24px;
}
.errorBoundary_content p {
  font-size: var(--mediumParaGraph);
  color: var(--white);
  font-weight: 400;
  font-style: italic;
  margin-bottom: 32px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ErrorPage/ErrorPage.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,kCAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AAAE;EACE,gBAAA;EACA,WAAA;EACA,YAAA;AAEJ;AAAE;EACE,gBAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,6CAAA;EACA,uDAAA;AAEJ;AAAI;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;AAEN;AAAI;EAbF;IAcI,kBAAA;EAGJ;AACF;AAFI;EACE,4BAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AAIN;AADI;EACE,gCAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAGN;AADI;EACE,iCAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAGN","sourcesContent":[".errorBoundary {\n  text-align: center;\n  padding: 15px;\n  background-color: var(--Mainblack);\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  img {\n    max-width: 121px;\n    width: 100%;\n    height: 50px;\n  }\n  &_content {\n    max-width: 604px;\n    width: 100%;\n    padding: 80px 40px;\n    border-radius: 8px;\n    background-color: #0000005c;\n    box-shadow: 0px 0px 22px -8px rgba(255, 255, 255, 0.13);\n\n    .back-to-home {\n      display: flex;\n      align-items: center;\n      margin: 30px 164px 0px;\n    }\n    @media (max-width: 767px) {\n      padding: 40px 15px;\n    }\n    h4 {\n      font-size: var(--headingOne);\n      color: var(--white);\n      font-weight: 600;\n      margin-bottom: 16px;\n      margin-top: 20px;\n    }\n\n    h6 {\n      font-size: var(--largeParaGraph);\n      color: var(--white);\n      font-weight: 400;\n      margin-bottom: 24px;\n    }\n    p {\n      font-size: var(--mediumParaGraph);\n      color: var(--white);\n      font-weight: 400;\n      font-style: italic;\n      margin-bottom: 32px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
