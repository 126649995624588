/**
 * WalletMenu Component
 *
 * Displays the user's wallet balance and the total amount invested.
 *
 */
import React from "react";
import { env, msgs } from "../../../utils/appConstants";

function WalletMenu({ metaBalance }) {
  return (
    <div className="walletMenu">
      <div className="innerWalletMenu">
        <h2>{msgs.amountInvested}</h2>
        <p>
          {metaBalance?.betsPlaced} {env.currency}
        </p>
      </div>
      <div className="innerWalletMenu">
        <h2>{msgs.fundsAvailable}</h2>
        <p>
          {metaBalance?.userBalance} {env.currency}
        </p>
      </div>
    </div>
  );
}

export default WalletMenu;
